<template>
  <div>
    <div class="profile">Personal Data</div>
    <div class="proRevise">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Personal Data" name="first">
          <el-form
            label-position="top"
            label-width="100px"
            :model="formLabelAlign"
            style="max-width: 460px; margin-bottom: 30px"
          >
            <el-form-item label="Display Name:" class="infor-input">
              <el-input v-model="formLabelAlign.nickname" />
            </el-form-item>
            <!-- <el-form-item label="username:">
                            <el-input v-model="formLabelAlign.username" />
                        </el-form-item> -->
            <div class="infor-word">
              <p>A-Z, 3-16 characters</p>
              <ul>
                <li>Your display name will appear on your profile</li>
              </ul>
            </div>
            <el-form-item label="Gender" prop="gender">
              <el-select v-model="formLabelAlign.gender" placeholder="Gender">
                <el-option label="Male" :value="0" />
                <el-option label="Female" :value="1" />
                <el-option label="Other" :value="2" />
              </el-select>
            </el-form-item>
            <el-form-item label="Recommended from">
              <el-input v-model="formLabelAlign.type" placeholder="Recommended" />
            </el-form-item>
            <el-form-item label="About My Services">
              <el-input
                v-model="formLabelAlign.aboutMyService"
                :autosize="{ minRows: 4, maxRows: 4 }"
                type="textarea"
                placeholder="About My Services"
              />
            </el-form-item>

            <el-form-item label="Experlence & Qualifications">
              <el-input
                v-model="formLabelAlign.qualifications"
                :autosize="{ minRows: 4, maxRows: 4 }"
                type="textarea"
                placeholder="Experlence & Qualifications"
              />
            </el-form-item>

            <el-form-item label="Other">
              <el-input
                v-model="formLabelAlign.other"
                :autosize="{ minRows: 4, maxRows: 4 }"
                type="textarea"
                placeholder="Other"
              />
            </el-form-item>
          </el-form>
          <p style="margin-bottom: 20px">
            <el-button
              type="primary"
              @click="submit"
              :loading="createLoading"
              :disabled="resetDisable"
              round
              >Revise</el-button
            >
          </p>
        </el-tab-pane>
        <el-tab-pane label="Change password" name="second">
          <el-form
            :model="form"
            ref="passWordFromRef"
            style="max-width: 460px; margin-bottom: 30px"
            label-width="150px"
            label-position="top"
            :rules="passWordRules"
          >
            <el-form-item label="Old password:" label-width="150px" prop="oldPwd">
              <el-input v-model="form.oldPwd" autocomplete="off" show-password />
            </el-form-item>
            <el-form-item label="New password:" label-width="150px" prop="newPwd">
              <el-input
                v-model="form.newPwd"
                autocomplete="off"
                show-password
                clearable
              />
            </el-form-item>
            <el-form-item
              label="Confirm password:"
              label-width="150px"
              prop="checkPassword"
            >
              <el-input
                v-model="form.checkPassword"
                autocomplete="off"
                show-password
                clearable
              />
            </el-form-item>
          </el-form>
          <p>
            <el-button
              type="primary"
              round
              @click="confirm"
              :loading="passLoading"
              :disabled="passDisable"
              >Confirm modification</el-button
            >
          </p>
        </el-tab-pane>
        <el-tab-pane label="Speciality" name="third">
          <p class="typle">
            Here you can choose your expertise. In this way, members can search for you
            through the services you provide and know what to expect. Please note that you
            can become an expert in up to 3 sections
          </p>
          <div class="typle-list" v-if="state.typeList">
            <template v-for="item in state.typeList" :key="item.id">
              <h3>
                {{ state[`typeList${item.id}`].name
                }}<span style="margin-left: 8px"
                  >{{ state[`changeList${item.id}`].length }}/3</span
                >
              </h3>
              <div class="change-list">
                <el-checkbox-group
                  v-model="state[`changeList${item.id}`]"
                  size="large"
                  :min="0"
                  :max="3"
                >
                  <el-checkbox-button
                    v-for="city in state[`typeList${item.id}`].categoryList"
                    :key="city.id"
                    :label="city.id"
                    :value="city.id"
                    @change="onChange(city)"
                  >
                    {{ city.name }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Bind paypal" name="fourth">
          <div
            v-show="formLabelAlign"
            v-loading="paypalLoading"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <!-- 1未绑定  0绑定 -->
            <div class="bind_body" v-if="!formLabelAlign.paypalEmail">
              <!-- You do not have a PayPal account, click the button below to bind -->
              Coming Soon
            </div>
            <div class="bind_body" v-else>
              <el-col :sm="12" :lg="6">
                <el-result icon="success" title="Account is bound">
                  <template #extra>
                    <p>
                      Your PayPal account is
                      <span style="color: #409eff; font-weight: bold">
                        {{ formLabelAlign.paypalEmail }}
                      </span>
                    </p>
                  </template>
                </el-result>
              </el-col>
              <p>If you want to change your PayPal account, click the connect button.</p>
            </div>
            <span id="paypalbtn"></span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
paypal.use(["login"], function (login) {
  login.render({
    // "appid": "AcYdeoZ-GDMWQQUbmvC2wl2XLxyZfec4B1Q9um8Y7pxa7qptPPxda0Kk0v8rY7sQXJgD7-SIAtIq0GCj",//本地开发ketian   key
    appid:
      "AV9eCn4LUPOGCg1Dh4ko_gr__D6E5I0SZVcBy9L4VucK2k4PO5wHvICBapsad5jVSVJkqWjfiKVYRFkq", //用户key
    authend: "live",
    scopes:
      "openid profile email address https://uri.paypal.com/services/paypalattributes",
    containerid: "paypalbtn",
    responseType: "code id_Token",
    locale: "en-us",
    buttonType: "CWP",
    buttonShape: "pill",
    buttonSize: "sm",
    fullPage: "false",
    // "returnurl": "http://127.0.0.1:3000/#/paypalLoginReturn",
    returnurl: "https://zestwisdom.com/#/paypalLoginReturn",
    // "returnurl": "http://192.168.1.100:3000/#/paypalLoginReturn",
    // http://192.168.1.100:3000/#/paypalLoginReturn
  });
});
</script>
<script setup>
import { reactive, ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  reviseConsulantInformation,
  getTypeList,
  addTypeList,
  getConmation,
  updateConPass,
} from "@/api/center.js";
const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const store = useStore();
const emits = defineEmits(["getInformation"]);
const checked = ref(false);
const paypalLoading = ref(true);
const paypalStatus = ref(1);
const createLoading = ref(false);
const resetDisable = ref(false);
const passLoading = ref(false);
const passDisable = ref(false);
const activeName = ref("first");
const handleClick = (tab, event) => {
  console.log(tab, event, "pp");
};
// const typeList1 = ref([])
const state = reactive({
  typeList: {},
  typeList1: [],
  typeList2: [],
  typeList4: [],
  typeList5: [],
  typeList6: [],
  changeList1: [],
  changeList2: [],
  changeList4: [],
  changeList5: [],
  changeList6: [],
  waitList: [],
});
const formLabelAlign = ref({
  fristName: "",
  lastName: "",
  aboutMyService: "",
  qualifications: "",
  other: "",
  name: "",
  year: "",
  mouth: "",
  day: "",
  region: "",
  type: "", ///推荐
  grade: "", ///等级
});
const form = ref({
  oldPwd: "",
  newPwd: "",
  checkPassword: "",
});
const checkpass = (rule, value, callback) => {
  if (value == form.value.newPwd) {
    callback();
  } else {
    callback(new Error("Password inconsistency"));
  }
};
const validatePass = (rule, value, callback) => {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
  if (!value) {
    return callback(new Error("Please enter the password"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    callback(
      new Error(
        "Please enter a 8-15 alphanumeric password. Must contain at least 1 number"
      )
    );
  }
};

const passWordRules = ref({
  oldPwd: [
    { required: true, message: "Please enter your old password", trigger: "blur" },
    { validator: validatePass, trigger: "blur" },
  ],
  newPwd: [
    { required: true, message: "Please enter your new password", trigger: "blur" },
    { validator: validatePass, trigger: "blur" },
  ],
  checkPassword: [
    { required: true, message: "Cannot be empty", trigger: "blur" },
    { validator: checkpass, trigger: "blur" },
  ],
});
const passWordFromRef = ref(null);

const bigList = reactive([
  { index: 1, value: "Astrology" },
  { index: 2, value: "Feng Shui" },
  { index: 3, value: "Bazi" },
  { index: 4, value: "Qi Men Dun Jia  Zhi" },
  { index: 5, value: "Zhi Wei Dou Shu" },
  { index: 6, value: "Tarot" },
  { index: 7, value: "Yiching" },
  { index: 8, value: "Numerology" },
  { index: 9, value: "Date Selection" },
  { index: 10, value: "Face Reading" },
]);
const smallList = reactive([
  { index: 1, value: "Love" },
  { index: 2, value: "Career" },
  { index: 3, value: "Destiny" },
  { index: 4, value: "Business" },
  { index: 5, value: "Family" },
  { index: 6, value: "Wealth" },
  { index: 7, value: "Health" },
  { index: 8, value: "Dreams" },
  { index: 9, value: "Date Selection" },
  { index: 10, value: "Energy Work" },
]);
const years = reactive([
  { value: "Option1", label: "1998" },
  { value: "Option2", label: "1999" },
  { value: "Option3", label: "2000" },
  { value: "Option4", label: "2001" },
  { value: "Option5", label: "2002" },
]);

const mouths = reactive([
  { value: "Option1", label: "01" },
  { value: "Option2", label: "02" },
  { value: "Option3", label: "03" },
  { value: "Option4", label: "04" },
  { value: "Option5", label: "05" },
]);

const days = reactive([
  { value: "Option1", label: "01" },
  { value: "Option2", label: "02" },
  { value: "Option3", label: "03" },
  { value: "Option4", label: "04" },
  { value: "Option5", label: "05" },
]);
onMounted(() => {
  getTypeListFn();
  setTimeout(() => {
    paypalLoading.value = false;
  }, 2000);
  // getConsuLant()
});
const getConsuLant = () => {
  paypalLoading.value = true;
  getConmation().then((res) => {
    store.commit("setUserInfo", JSON.stringify(res.data));
    if (res.code == 200) {
      paypalLoading.value = false;
    } else {
      paypalLoading.value = false;
      ElMessage.error(res.msg ? res.msg : "error");
    }
    formLabelAlign.value = res.data;
    res.data.categoryList.forEach((item) => {
      if (item.hasOwnProperty("categoryList")) {
        delete item.categoryList;
        item = item;
      }
    });
    res.data.categoryList.forEach((item) => {
      state[`changeList${item.pid}`] = Array.from(
        new Set([...state[`changeList${item.pid}`], item.id])
      );
    });
  });
};

const getTypeListFn = () => {
  getTypeList().then((res) => {
    if (res.code == 200) {
      state.typeList = res.rows;
      console.log(state.typeList, "state.typeList总 ");
      res.rows.forEach((item) => {
        state[`typeList${item.id}`] = item;
        state[`changeList${item.id}`] = [];
      });
      getConsuLant();
    }
  });
};

//保存信息
const submit = () => {
  createLoading.value = true;
  resetDisable.value = true;
  formLabelAlign.value.consultantId = formLabelAlign.value.id;
  reviseConsulantInformation(formLabelAlign.value).then((res) => {
    if (res.code == 200) {
      console.log(res, res);
      createLoading.value = false;
      resetDisable.value = false;
      ElMessage({
        message: "Modified successfully",
        type: "success",
      });
      emits("getInformation");
      // emits('getInformation',{a:1,b:2})  //传参数
      // getConsuLant()
    } else {
      ElMessage.error({
        message: "Modification failed",
      });
      createLoading.value = false;
      resetDisable.value = false;
    }
  });
};

//选择分类
const onChange = (item) => {
  const param = { consultantId: props.consultantId, id: item.id };
  addTypeList(param).then((res) => {
    if (res.code == 200) {
      // getConsuLant()
      // ElMessage({
      //     message: 'Add successfully',
      //     type: 'success'
      // })
    }
  });
};

//修改密码
const confirm = () => {
  passWordFromRef.value.validate((valid) => {
    if (valid) {
      passLoading.value = true;
      passDisable.value = true;
      const params = {
        id: formLabelAlign.value.id,
        oldPwd: form.value.oldPwd,
        newPwd: form.value.newPwd,
      };
      updateConPass(params).then((res) => {
        if (res.code == 200) {
          passLoading.value = false;
          passDisable.value = false;
          passWordFromRef.value.resetFields();
          ElMessage({ message: "Password changed successfully", type: "success" });
        } else {
          passLoading.value = false;
          passDisable.value = false;
          ElMessage({ message: res.msg, type: "warning" });
        }
      });
    } else {
    }
  });
};
</script>

<style lang="scss" scoped>
// proRevise
@media screen and (max-width: 768px) {
  .proRevise {
    padding: 0 !important;
  }
}
::v-deep(.el-result__title) {
  margin-top: 0;
}

::v-deep(.el-result__extra) {
  margin-top: 0;
}

::v-deep(.el-result) {
  padding-bottom: 0;
}

.bind_body {
  width: 100%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 50px;
}

.profile {
  line-height: 50px;
  border-bottom: 1px solid #c2bcbc;
  padding-left: 20px;
  color: #02758a;
}

.proRevise {
  padding: 30px 50px;

  .infor-input {
    color: rgb(143, 119, 164);
  }

  .infor-word {
    font-size: 12px;
    color: rgb(143, 119, 164);
    margin-bottom: 30px;
  }

  .next {
    width: 100px;
    text-align: center;
    padding: 10px 20px;
    background-color: #f3c90e;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
  }
}

.typle {
  line-height: 25px;
  padding: 20px;
  color: rgb(64, 150, 165);
}

.typle-list {
  h3 {
    color: #02758a;
  }

  .change-list {
    margin: 10px 10px;

    .change-list {
      margin: 0 10px;
    }
  }

  .el-check-tag {
    margin: 10px 10px;
  }

  .big-list {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;

    .change-active {
      list-style: none;
      margin: 10px;
      padding: 5px 10px;
      background-color: #dfe2e2;
      border-radius: 15px;
      color: #146e65;
      font-size: 12px;
      cursor: pointer;
    }

    .tags-select-active {
      background-color: #dfe2e2;
      color: #02758a;
    }
  }

  .el-checkbox-button {
    margin: 10px 10px;
    border: 1px;
  }

  :deep(.el-checkbox-button__inner) {
    border: 1px solid #d8d4d4;
    border-radius: 20px;
  }
}
</style>
